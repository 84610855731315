
<template>
  <div>
     <!--- 加入会议按钮 --->
     <van-button type="primary" :loading="loading"
          :loading-text="loadingText" class="button" @click="handleClick" :class="{'active': active }">
       <slot></slot>
     </van-button>

     <!-- 入会密码弹窗 -->
    <join-password :showPwdWin="showPwdWin" @close="closeWin(0)" @join-handle="handleJoin"></join-password>

    <!-- 等待主持人开始当前会议 弹窗---->
    <wait-host :showHostWin="showHostWin" :params="waitParams" :title="hostWinTitle"  @close="closeWin(1)" :detail="detail"></wait-host>

    <!-- 主持人正在开另外一场会议 弹窗---->
    <other-host :showOtherWin="showOtherWin" :params="otherParams" :title="otherWinTitle"  @close="closeWin(2)"></other-host>

    <!-- 是否结束当前正在进行中的会议 弹窗---->
    <end-win :showEndWin="showEndWin" :params="endParams" :title="endWinTitle"  @close="closeWin(3)" :detail="endDetail"></end-win>
  </div>
</template>

<script>
import { throttle } from 'lodash'
import { THROTLE_TIME } from "@/constant/index";
import { setMeetInfo } from '@/utils/auth'
import { loganLog } from "@/utils/log"
import { wordsFilter} from '@/api/meet'

import JoinPassword from '@/components/dialog/JoinPassword'
import WaitHost from '@/components/dialog/WaitHost'
import OtherHost from '@/components/dialog/OtherHost'
import EndWin from '@/components/dialog/EndWin'
import { checkDevicePermission } from "@/utils/device";

export default {
  data() {
    return {
      showPwdWin: false,
      showHostWin: false,
      showOtherWin: false,
      showEndWin: false,
      conferenceNumber: '',
      detail: {},
      endDetail: {},
      endParams: {},
      waitParams: {},
      otherParams: {},
      hostWinTitle: '',
      otherWinTitle: '',
      endWinTitle: '',
      loading: false
    }
  },

  props: {
    active: { default : false },
    conferenceNo: { default : '' },
    userName: { default : '' },
    isNew: { default : false },
    loadingText: { default : '请求中...' }
  },

  components: {
    JoinPassword,
    WaitHost,
    OtherHost,
    EndWin
  },

  computed: {
  },
  mounted() {

    if(this.$route.query.autoJoin == 1) {
      // this.$router.replace({autoJoin: 0})
      setTimeout(() => {
        this.handleClick();
      }, 200)

    }

    //加入密码会议的事件监听
    this.$eventBus.$on('join-password-handle', data => {
      this.showPwdWin = true
    })

    //错误码60005<等待主持人开始当前会议> 的回调监听
    this.$eventBus.$on('wait-host-handle', (error, cb, params) => {
      this.detail = error?.data || {}
      this.hostWinTitle = error?.msg
      this.waitParams = { cb, params }
      this.showOtherWin = false
      this.showHostWin = true
    })

    //错误码60011<主持人正在开另外一场会议> 的回调监听
    this.$eventBus.$on('other-host-handle', (error, cb, params) => {
      this.otherWinTitle = error?.msg
      this.otherParams = { cb, params }
      this.showHostWin = false
      this.showOtherWin = true
    })

    //错误码60010<是否结束当前正在进行中的会议> 的回调监听
    this.$eventBus.$on('end-meeting-handle', (error, cb, params) => {
      this.endDetail = error?.data || {}
      this.endWinTitle = error?.msg
      const roomId = this.endDetail.roomId
      this.endParams = { cb, params, roomId }
      this.showEndWin = true
    })

    if(this.$route.query.autoJoin == 1) {
      // this.$router.replace({autoJoin: 0})
      setTimeout(() => {
        this.handleClick();
      }, 200)
      return;
    }

    this.onExceptionExit();
  },

  methods: {
    closeWin(mark) {
      switch (mark) {
        case 0:
          this.showPwdWin = false
          break;

        case 1:
          this.showHostWin = false
          break;

        case 2:
          this.showOtherWin = false
          break;

        case 3:
          this.showEndWin = false
          break;

        default:
          break;
      }
      this.loading = false
    },
    handleClick: throttle(async function() {
      if (!this.active) return

      this.loading = true
      if (this.isNew) {
        this.$emit('join-before', this.handleJoin)
      } else {
        this.handleJoin()
      }
    }, THROTLE_TIME,{ leading: true, trailing: false}),



    // 加入会议
    async handleJoin(joinPassword, conferenceNo, callback) {
      await checkDevicePermission()
      .then(() => {
        this.joinMeetFn(joinPassword, conferenceNo, callback)
      })
      .catch((error) => {
        if(error.type === "video"){
          this.$dialog.confirm({
            message:`系统检测未授权摄像头权限，是否继续加入会议`, 
            title:'提示', 
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() => {
            this.joinMeetFn(joinPassword, conferenceNo, callback)
          }).catch(() => {
            this.loading = false        
          });
        }else{
          this.$dialog.alert({
            message: '系统检测未授权麦克风权限，请关闭小程序重新打开后入会',
            confirmButtonText: '确定',
            confirmButtonColor: '#FF4D4F'
          })
          this.loading = false
          return
        }
      })
    },

    async joinMeetFn(joinPassword, conferenceNo, callback) {

      this.conferenceNumber = this.conferenceNo
      if(conferenceNo) {
        this.conferenceNumber = conferenceNo
      }
      if(this.$route.query.conferenceNo) {
        this.conferenceNumber = this.$route.query.conferenceNo
      }
      if (!this.conferenceNumber || !this.userName) {
        return
      }
      try {
        await wordsFilter({ 
          bizType:'meeting',
          text:this.userName
        })
      } catch (error) {
        let _msg = [102,103,199001].includes(error.code) ? '消息内容存在敏感词，请检查后重试' : '消息内容' + error.msg
        this.$toast(_msg)
        loganLog(`发送消息错误--error:${JSON.stringify(error)}，test:${this.userName}`)
        this.loading = false
        return
      }


      let data = {
        conferenceNo: this.conferenceNumber.replace(/\s+/g, ""),
        userName: this.userName,
        closeConference: true,
      }
      if(this.$parent.joinParamData && this.$parent.joinParamData.businessType == 10007){
        data.userId = this.$parent.joinParamData.userId
        data.accessToken = this.$parent.joinParamData.accessToken
      }

      if (joinPassword) {
        data.password = joinPassword
      }

      const userId = this.$route.query.userId;
      const appId = this.$route.query.appId;

      const from = this.$route.query.from;
      if(from) {
        sessionStorage.setItem('thirdBackUrl', from)
      }

      // if (userId || appId) {
        userId && (data.userId = userId);
        appId && (data.appId = appId)
      // }

      console.log('[debug info] userId', data)

      try {
        const resData = await sdk.meetingManagement.joinMeeting(data)
        const { conference, userid, roomid } = resData

        this.$emit('join-finish', resData)
        callback && callback(1, resData)
        loganLog(`[debug info] join meeting success ${JSON.stringify(resData)}` )
        // 存储token信息
        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        // 更新会议信息
        this.$store.commit("meet/updateGlobalMeetState", {
          ...conference
        })

        this.loading = false
        let queryData = {
            roomID: roomid, // 房间id
            conferenceNo: conference.conferenceNo, // 会议号
            conferenceId: conference.id, // 会议id
            userName: this.userName, // 展示名
          }
          if(this.$parent.joinParamData && this.$parent.joinParamData.businessType == 10007){
            queryData.businessType = this.$parent.joinParamData.businessType
            if (this.$parent.joinParamData.hospital){
              queryData.hospital = this.$parent.joinParamData.hospital || ''
            }
          }

        if(this.$route.query.autoJoin == 1) {
          this.$router.replace({
            name: "meeting",
            params: { userID: userid },
            query: queryData
          });
        } else {
          this.$router.push({
            name: "meeting",
            params: { userID: userid },
            query: queryData
          });
        }
      } catch (error) {
        this.loading = false
        this.$eventBus.$emit('join-error', error, this.handleJoin, [joinPassword, conferenceNo])
        callback && callback(0, error)
      }
    },

    /*** --------- 监听异常退出 --------- ***/
    onExceptionExit() {

      // 锁屏 后台相关
      const appHide = this.$meetingStorage.getItem('appHide');
      let isSameMeet = true
      if(this.$parent.joinParamData && this.$parent.joinParamData.conferenceNo != appHide){
        isSameMeet = false
      }
      if(appHide && isSameMeet) {
        this.$dialog.confirm({
          message: '异常退出会议，是否恢复会议',
          confirmButtonText: '重新入会',
          closeOnPopstate: false,
          transition:'fade'
        }).then(() => {
          localStorage.removeItem('appHide');
          localStorage.removeItem('joinBeforeRoute');

          this.handleJoin('', appHide);

        }).catch(() => {
          localStorage.removeItem('appHide');
          localStorage.removeItem('joinBeforeRoute');
        })


        return

      }

      const exceptionExitMsgObj = localStorage.getItem('exceptionExitMsg');


      if(!exceptionExitMsgObj) return;

      const { msg:exceptionExitMsg, conferenceNo } = JSON.parse(exceptionExitMsgObj);

      const _fun = message => {
        loganLog(message, '异常退会|' + conferenceNo)

        this.$dialog.confirm({
          message,
          confirmButtonText: '重新入会',
          closeOnPopstate: false,
          transition:'fade'
        }).then(() => {
          localStorage.setItem('exceptionExitMsg', '');

          console.log('joinButton exception', exceptionExitMsg, conferenceNo);
          this.handleJoin('', conferenceNo);

        }).catch(() => {
          localStorage.setItem('exceptionExitMsg', '')

        })

      }

      if(exceptionExitMsg == 'exception') {

        _fun('异常退出会议，是否恢复会议')
        return
      } else if(exceptionExitMsg == 'timeout') {
        _fun(this.$t('meeting.exception'))
        return
      } else if(exceptionExitMsg == 'enterRoomError') {
        _fun('进入房间失败')
        return
      }

    }


  }

};
</script>

<style lang="less" scoped>
   .button {
      width: 100%;
      height: 88px;
      background: #B5E6CA;
      border-radius: 10px;
      font-size: 32px;
      font-weight: 500;
      border:none;
   }
   .button.active {
      background: #1AB370 !important;
      color:#fff;
   }
</style>
