<template>
   <!--- 是否结束当前正在进行中的会议 --->
    <van-dialog 
      v-model="showEndDialog"
      :confirmButtonText="$t('login.end')"
      :cancelButtonText="$t('login.noEnd')"
      :before-close="beforeCloseHandle"
      class="end-host"
      show-cancel-button>
      <div class="title">{{ title }}</div>
      <div class="content">
        <div>{{ $t('login.meetNumber') }}{{ detail.conferenceNo | formatCodeFilter }}</div>
        <div>{{ $t('login.meetTheme') + detail.title }}</div>
      </div>
    </van-dialog>
</template>

<script>
import '@/utils/filter'
import { loganLog } from "@/utils/log"
export default {
  data() {
    return {
       showEndDialog: false
    }
  },
  props: {
    showEndWin: Boolean,
    detail: Object,
    params: Object,
    title: String
  },
  watch: {
    showEndWin: {
      handler(value) {
        this.showEndDialog = value
      },
      immediate: true
    } 
  },
  computed: {
    
  },
  mounted() {
  },
  
  methods: {
    beforeCloseHandle: async function(action, done) {
      if (action === 'confirm') {
        const { roomId, cb, params } = this.params
        try {
          await sdk.meetingManagement.endMeeting(roomId)
        } catch (error) {
          loganLog(error, 'sdk异常|结束会议')
        }
        done()
        this.$emit('close')
        console.error(params)
        cb && cb.call(this, ...params)
      } else {
        done()
        this.$emit('close')
      }
    },
  }
};
</script>

<style lang="less" scoped>
.end-host {
     width: 580px;

     .title {
        font-size: 36px;
        font-weight: 600;
        color: #000000;
        margin-top: 50px;
        text-align: center;
     }
     .content {
        font-size: 30px;
        font-weight: 400;
        color: #999999;
        line-height: 44px;
        margin-top: 30px;
        margin-bottom: 60px;
        padding-left:40px;
        padding-right:40px;
        text-align: left;
     }
} 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
/deep/ .van-dialog__cancel {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}
::v-deep .van-dialog__confirm, .van-dialog__confirm:active {
  color:#FF4D4F;
}
</style>
