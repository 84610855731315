<template>
   <!--- 主持人正在开另外一场会议 --->
    <van-dialog 
      v-model="showOtherDialog"
      :confirmButtonText="$t('login.leaveMeet')"
      :before-close="beforeCloseHandle"
      class="other-host">
      <van-loading color="#1DAF69" class="loading"></van-loading>
      <div class="title">{{ title }}</div>
      <div class="content">
        <div>{{ $t('meeting.waitHost') }}</div>
      </div>
    </van-dialog>
</template>

<script>
export default {
  data() {
    return {
       showOtherDialog: false,
       otherHostTimer: null
    }
  },
  props: {
    showOtherWin: Boolean,
    params: Object,
    title: String
  },
  watch: {
    showOtherWin: {
      handler(value) {
        this.showOtherDialog = value

        //弹窗不关闭 定时入会
        if (value) {
           this.otherHostTimer = setInterval(() => {
            console.error('otherHostTimer计时器再走')
            const { cb, params } = this.params
            cb && cb.call(this, ...params)
          }, 15000)
        } else {
          this.clearTimers()
        }
      },
      immediate: true
    } 
  },
  computed: {
    
  },
  mounted() {
  },
  
  methods: {
    clearTimers() { //清理计时器引用
      this.otherHostTimer && clearInterval(this.otherHostTimer)
      this.otherHostTimer = null
    },
    beforeCloseHandle(action, done) {
      this.clearTimers()
      done()
      this.$emit('close')
    },
  },

  beforeDestroy() {
    this.clearTimers()
  }
};
</script>

<style lang="less" scoped>
.other-host {
     width: 580px;

     .loading {
       width: 80px;
       height: 80px;
       margin: 0 auto;
       margin-top: 50px;
     }

     .title {
        font-size: 36px;
        font-weight: 600;
        color: #000000;
        margin-top: 50px;
        text-align: center;
     }
     .content {
        font-size: 30px;
        font-weight: 400;
        color: #999999;
        line-height: 44px;
        margin-top: 30px;
        margin-bottom: 148px;
        text-align: center;
     }
} 

/deep/ .van-dialog {
  background: #FFFFFF;
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
::v-deep .van-dialog__confirm, .van-dialog__confirm:active {
  color:#FF4D4F;
}
</style>
